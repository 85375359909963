<template>
  <div>
    <Divider
      dashed
      style="color:#fff"
    >设置监播内容</Divider>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20"><span class="text-orange">*</span>作业日期</h4>
      <DatePicker
        v-model="actionDate"
        size="small"
        type="date"
        placeholder="作业日期"
        style="width: 100%"
        :clearable="false"
        :editable="false"
        @on-change="changeActionDate"
      ></DatePicker>
    </div>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">拍照要求（<span class="text-orange">最多选两项</span>）</h4>
      <CheckboxGroup
        size="small"
        class="m-b-10"
        v-model="selectedRemarks"
      >
        <Checkbox
          v-for="mark in markArray"
          :key="mark.value"
          :label="mark.value"
          :disabled="selectedRemarks.length>1&&!selectedRemarks.includes(mark.value)"
        >{{mark.name}}</Checkbox>
      </CheckboxGroup>
      <Input
        v-model.trim="extras"
        size="small"
        type="textarea"
        :rows="3"
        placeholder="自定义要求"
        style="width: 100%"
      />
    </div>
    <div class="text-right">
      <Button
        type="success"
        size="small"
        :disabled="!actionDate||selectedTaskIds.length===0"
        :loading="submitLoading"
        @click="handleSubmit"
      >确认设置</Button>
    </div>
  </div>
</template>

<script>
import { getPhotoDemandDictList } from '@/api/msp/dictionary'
import { createInspectTask, updateTaskitem } from '@/api/msp/demandV3'
export default {
  data () {
    return {
      markArray: [],
      actionDate: '',
      selectedRemarks: [],
      extras: '',
      submitLoading: false
    }
  },
  created () {
    this.getRemarkArray()
  },
  computed: {
    // demandId () {
    //   return this.$store.state.installSetting.demandId
    // },
    setOperatingType () {
      return this.$store.state.installSetting.setOperatingType
    },
    selectedTaskIds: {
      get () {
        return this.$store.state.installSetting.selectedTaskIds
      },
      set (val) {
        this.$store.commit('set_selected_taskIds', val)
      }
    }
  },
  methods: {
    getRemarkArray () {
      getPhotoDemandDictList().then(res => {
        this.markArray = res
      })
    },
    changeActionDate (date) {
      this.actionDate = date
    },
    handleSubmit () {
      if (!this.actionDate) {
        this.$Notice.error({ desc: '请填写作业日期' })
        return false
      }
      const extrasRemark = []
      this.markArray.forEach(item => {
        if (this.selectedRemarks.some(x => x === item.value)) {
          extrasRemark.push(item.name)
        }
      })
      this.submitLoading = true
      if (this.setOperatingType === 1) { // 新增
        const postData = {
          taskIds: JSON.stringify(this.selectedTaskIds),
          actionDate: this.actionDate,
          extras: this.extras,
          remark: extrasRemark.toString()
        }
        createInspectTask(postData).then(res => {
          this.submitLoading = false
          if (res && res.errcode === 0) {
            // 切换到监播列表
            this.$store.commit('set_demand_type', 2)
            // 左侧切换到素材管理
            this.$store.commit('set_left_component', 'MaterialList')
            this.handleSetSuccess()
          }
        })
      } else {
        // 编辑
        const postData = {
          taskitemIds: JSON.stringify(this.selectedTaskIds),
          actionDate: this.actionDate,
          extras: this.extras,
          remarks: extrasRemark.toString()
        }
        updateTaskitem(postData).then(res => {
          this.submitLoading = false
          if (res && res.errcode === 0) {
            this.$store.dispatch('getTaskitemData')
            this.handleSetSuccess()
          }
        })
      }
    },
    handleSetSuccess () {
      // this.$store.dispatch('getMonitorResourceData')
      this.actionDate = ''
      this.selectedRemarks = []
      this.extras = ''
      this.selectedTaskIds = []
      this.$Notice.success({ desc: '操作成功' })
    }
  }
}
</script>
